// https://github.com/hotwired/turbo/issues/96

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form', 'status']

    connect() {
      // console.log("STIMULUS AUTOSAVE!!!")
      this.timeout = null
      this.duration = this.data.get('duration') || 1000
    }

    save() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.setStatus("")
        Rails.fire(this.formTarget, 'submit')
      }, this.duration)
    }

    save_employment() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.setStatus("")
        Rails.fire(this.formTarget, 'submit')
      }, this.duration)
    }

    async submitted(event) {
      // console.log("--------------")
      // console.log(event)
      // console.log("--------------")
      
      document.getElementById('mysubmit').removeAttribute("disabled");
      
      let response = await event.detail.fetchResponse.response

      if(response.ok) {
          this.setStatus("Saved")
      } else {
          this.setStatus("Error", response.errors)
      }
    }

    setStatus(message, error="") {
      this.statusTarget.style.color = "grey";

      if(message == "Error") {

       this.statusTarget.style.color = "red";
       message =  `${message}: ${JSON.stringify(error)}`

      } else if(message == "Saved")  {
        this.statusTarget.style.color = "#00cd70";
      } else {
        // message = "Saving..."
      }

      // this.statusTarget.textContent = message

      this.timeout = setTimeout(() => {
           this.statusTarget.textContent = ''
         }, 2000)
    }
}