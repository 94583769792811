import { Controller } from "@hotwired/stimulus"
//import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.addSelectedClass.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('click', this.addSelectedClass.bind(this));
  }

  addSelectedClass(event) {
    // Ensure the event target is the <a> element
    const target = event.target.closest('.chat-item a');
    if (!target) return;

    event.preventDefault();

    // Remove 'selected_class' from all chat item divs
    document.querySelectorAll('.chat-item').forEach((chatItem) => {
      chatItem.classList.remove('selected_class');
    });

    // Get the parent div of the clicked link
    const parentDiv = target.parentElement;

    // Add 'selected_class' to the parent div of the clicked link
    parentDiv.classList.add('selected_class');

    // Now do the redirect
    let url = target.getAttribute('href');
    Turbo.visit(url); // Turbo-frames will handle this visit
  }
}
