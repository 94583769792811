import { Controller } from "@hotwired/stimulus"
import Tags from "bootstrap5-tags"

export default class extends Controller {
 static values = { badgeStyle: String }

 connect() {
  new Tags(this.element, { allowClear: true, clearEnd: true, badgeStyle: this.badgeStyleValue })
 }
}
