import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['chatwindow', "chatmessages"]

  connect() {
    this.scrollOnNewMessage = this.scrollOnNewMessage.bind(this);
    document.addEventListener('turbo:before-stream-render', this.scrollOnNewMessage);
    this.scrollToBottom();
  }

  disconnect() {
    document.removeEventListener('turbo:before-stream-render', this.scrollOnNewMessage);
  }

  scrollOnNewMessage() {
    setTimeout(() => {
      this.scrollToBottom();
      if(this.statusDiv() != null) {
      this.statusDiv().innerHTML = "online"
      this.statusDiv().style.color = "#e6007a";
      }
      this.textArea().focus();
    }, "100");
  }

  open() {
    this.chatwindowTarget.style.display = "block";
    this.scrollToBottom();
    this.textArea().focus();
  }

  close() {
    this.chatwindowTarget.style.display = "none"; 
  }

  disableForm() {
    this.textArea().placeholder="";
    this.submitButton().disabled = true;
    this.submitButton().className += " my-btn-disabled";
    this.submitButton().value = "";
    if(this.statusDiv() != null) {
      this.statusDiv().innerHTML = "<span class='box'><span>.</span><span>.</span><span>.</span>typing<span>"
      this.statusDiv().style.color = "#bbbbbb";
    }
  }

  enterPressed(event) {
    if (this.submitButton().disabled) {
      // console.log("btn disabled")
      return
    }

    if (event.keyCode == 13 && event.metaKey) {
      // console.log("enter pressed")
      this.chatForm().requestSubmit();
    }
  }

    // enterPressed(event) {
    //   this.textArea().value = this.textArea().value.trim()
    //   if (event.keyCode == 13 && !event.shiftKey && !event.metaKey) {
    //       if(this.textArea().value == "") {
    //         console.log("empty string")
    //         this.textArea().value = ""
    //         event.preventDefault();
    //       }
    //       this.chatForm().requestSubmit();
    //     }
    //   }

    // shiftEnterPressed(event) {
    //   if (event.keyCode == 13 && event.shiftKey) {
    //     console.log("shift + enter pressed")
    //     //this.textArea().value = this.textArea().value + " h1 "
    //   }
    // }

  submitButton() {
    return this.element.querySelector("#submit-button");
  }

  textArea() {
    return this.element.querySelector("#chat_message_user_question");
  }

  scrollToBottom() {
    this.chatmessagesTarget.scrollTop = this.chatmessagesTarget.scrollHeight;
  }

  statusDiv() {
    return document.getElementById("statusdiv")
  }

  chatForm() {
    return document.getElementById("my-chat-form")
  }

}
