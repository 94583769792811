import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turnstile"
export default class extends Controller {
  static values = { siteKey: String }
  static targets = [ "button", "container", "input" ]

  connect() {
    // https://developers.cloudflare.com/turnstile/reference/testing/
    this.siteKeyValue = "0x4AAAAAAAD8pBB2F5BQoFKr" // ur site public key or test key

    if (!window.turnstile) {
      this.loadTurnstileScript()
    }
    
    this.element.addEventListener("submit", (event) => {
      if (this.inputTarget.value === "") {
        event.preventDefault()
        this.initTurnstile()
      }
    })
  }

  loadTurnstileScript() {
    const script = document.createElement("script")
    script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=onTurnstileLoad"
    script.defer = "defer"
    script.async = true

    window.onTurnstileLoad = () => {}
    document.body.appendChild(script)
  }

  disconnect() {
    if (this.widgetId) {
      turnstile.remove(this.widgetId)
    }
  }

  disableButton() {
   // this.buttonTarget.disabled = true
    //this.buttonTarget.style.display = 'none'
    this.buttonTarget.remove();
    // this.buttonTarget.value = "Please compelte the captch above"
    // this.buttonTarget.classList.add("cursor-not-allowed.opacity-50")
  }

  enableButton() {
    // this.buttonTarget.disabled = false
    // this.buttonTarget.value = "Please wait..."
    // this.buttonTarget.classList.remove("cursor-not-allowed.opacity-50")
  }

  initTurnstile() {
    this.disableButton()

    this.widgetId = turnstile.render(this.containerTarget, {
      sitekey: this.siteKeyValue,
      theme: "dark",
      "before-interactive-callback": () => {
        this.disableButton()
        this.containerTarget.classList.remove("hidden")
      },
      "after-interactive-callback": () => {
        this.containerTarget.classList.add("hidden")
        this.enableButton()
      },
      callback: (token) => {
        // Success!
        this.inputTarget.value = token
        this.inputTarget.form.submit()
      },
    })
  }
}