import { Controller } from "@hotwired/stimulus"
//import { Modal } from 'bootstrap';

export default class extends Controller {

  static targets = [
    // "question_1",
    "cvField",
    "first_name",
    "last_name",
    "public_email",
    "phone",
    "linkedin",
    "location_country_residency",
    "website",
    "summary",
    "cvModal",
    "cvModalProgressBar"
  ];

  connect() {
    console.log("hi from parsecv js")
    // this.modal = new Modal(this.cvModalTarget);
    import('bootstrap').then((Bootstrap) => {
      this.modal = new Bootstrap.Modal(this.cvModalTarget);
    });
  }

  animateProgressBar() {

    const totalDuration = 20 * 1000; // seconds

    const interval = 100; // milliseconds
    const step = (100 * interval) / totalDuration; // calculate step for each interval
    let width = 0;

    const progressInterval = setInterval(() => {
      if (width >= 100) {
        clearInterval(progressInterval);
      } else {
        width += step;
        this.cvModalProgressBarTarget.style.width = width + '%';
        this.cvModalProgressBarTarget.setAttribute('aria-valuenow', width);
      }
    }, interval);
  }




  uploadAndParse() {
    let formData = new FormData();
    formData.append("cv", this.cvFieldTarget.files[0]);

    // let question1Label = this.element.querySelector("#label_question_1")

    // if(question1Label !== undefined && question1Label !== null) {
    //   let question1_text = question1Label.textContent
    //   console.log("question1_text: " + question1_text)
    //   formData.append("question_1", question1_text);
    // }

    this.modal.show();
    this.animateProgressBar();

    fetch('/upload_file', {
      method: 'POST',
      body: formData,
      headers: {'X-CSRF-Token': this.getCSRFToken()}
    })
    .then(response => response.json())
    .then(data => {
      this.prefillForm(data)
      this.modal.hide();
    });
  }

  prefillForm(data) {
    // console.log(data)

    var whatWasFileld = []

    if(data == undefined || data == null || data =='') {
      alert("❌ No fields found. Try another CV");
      this.modal.hide();
      return ;
    }


    // if(this.isOk(data.question_1)) {
    //   this.question_1Target.value = data.question_1
    //   whatWasFileld.push("Question 1\n")
    // }
    
    if(this.isOk(data.first_name)) {
      this.first_nameTarget.value = data.first_name
      whatWasFileld.push("First name")
    }

    if(this.isOk(data.last_name)) {
      this.last_nameTarget.value = data.last_name
      whatWasFileld.push("Last name")
    }

    if(this.isOk(data.public_email)) {
      this.public_emailTarget.value = data.public_email
      whatWasFileld.push("Email")
    }

    if(this.isOk(data.phone)) {
      this.phoneTarget.value = data.phone
      whatWasFileld.push("Phone")
    }

    if(data.location_country_residency!=""){
      this.location_country_residencyTarget.value = data.location_country_residency
      whatWasFileld.push("Location")
    }

    if(this.isOk(data.linkedin)){
      this.linkedinTarget.value = data.linkedin
      whatWasFileld.push("LinkedIn")
    }

    if(this.isOk(data.github)){
      this.linkedinTarget.value = data.github
      whatWasFileld.push("GitHub")
    }

    if(this.isOk(data.twitter)){
      this.linkedinTarget.value = data.twitter
      whatWasFileld.push("Twitter")
    }

    if(this.isOk(data.website)){
      this.websiteTarget.value = data.website
      whatWasFileld.push("Website")
    }

    if(this.isOk(data.bio)){
      this.summaryTarget.value = data.bio
      whatWasFileld.push("Additional Information")
    }

    if(data.employment_history !== undefined && data.employment_history.length !== 0) {
      let employment_history_str = this.employmentHistoryToStr(data.employment_history)
      this.summaryTarget.value = this.summaryTarget.value + "\n\n" + employment_history_str
      whatWasFileld.push("Employment History")
    }


    if (whatWasFileld.length == 0) {
      alert("No fields found. Try another CV")
    } else {
      alert("✅ We autofilled these:\n - " + whatWasFileld.join('\n - '))
    }

    this.modal.hide();
  }

  isOk(value) {
    return value !== null && value !== undefined && value !== '';
  }

  employmentHistoryToStr(records) {
    // console.log(records)
    let all = "\n\nEmployment History:\n"
    records.forEach((record) => {
      let sentence = `${record.title} at ${record.company} (${record.year_start} - ${record.year_end})\n${record.description}\n\n`;
      all = all + sentence
    });
    // console.log(all)
    return all
  }

  showBlurOverlay() {
     document.querySelector('.blur-overlay').style.display = 'block';
     this.mainContentTarget.classList.add('blur-content');
   }

  getCSRFToken() {
      const meta = document.querySelector('meta[name="csrf-token"]');
      return meta ? meta.getAttribute('content') : '';
    }
}
